import { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Pagination } from 'dashboard/components/table';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  COMMERCIAL_AGREEMENTS_QUERY,
  CommercialAgreementsList,
} from 'commercialAgreements/graphql/commercialAgreementsQuery';
import { CommercialAgreement } from 'commercialAgreements/models/commercialAgreement';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import CommercialAgreementsTable from 'commercialAgreements/components/table/CommercialAgreementsTable';
import { useHistory } from 'react-router';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import AddButton from 'dashboard/components/dashboard/AddButton';
import {
  CommercialAgreementDeleteMutationVariables,
  COMMERCIAL_AGREEMENT_DELETE_MUTATION,
  CommercialAgreementTypename,
} from 'commercialAgreements/graphql/commercialAgreementDeleteMutation';
import { updateCacheById } from 'graphql/apollo/cache';
import { useConfirm } from 'ui/contexts/overlay/Confirm';
import CommercialAgreementsFormFilters, {
  FormFilters,
} from 'commercialAgreements/components/form/CommercialAgreementsFormFilters';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formatDateToSubmitDateString } from 'utils/form';

type ListState = {
  commercialAgreements: CommercialAgreement[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Acordos Comerciais',
};

const DELETE_SUCCESS_TOAST: ToastProps = {
  text: 'Acordo deletado com sucesso',
  title: 'Sucesso',
  variant: 'primary',
};

const DETETE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar deletar o Acordo comercial',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const DASHBOARD_COMMERCIAL_AGREEMENTS_CREATE_ROUTE =
  '/dashboard/billing-cycle/commercial-agreements/create';

export default function ListCommercialAgreementsPage() {
  const { push } = useHistory();
  const { addToast } = useToastContext();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();
  const { ConfirmOverlay, closeConfirm, showConfirm } = useConfirm();
  const [listState, setListState] = useState<ListState>({
    commercialAgreements: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });
  const [currentCommercialAgreementId, setCurrentCommercialAgreementId] =
    useState<number>(0);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [commercialAgreementsList, { data, loading, error, refetch }] =
    useLazyQuery<CommercialAgreementsList>(
      COMMERCIAL_AGREEMENTS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  const [
    commercialAgreementDeleteMutation,
    { loading: commercialAgreementDeleteLoading },
  ] = useMutation<CommercialAgreementDeleteMutationVariables>(
    COMMERCIAL_AGREEMENT_DELETE_MUTATION,
    {
      onError() {
        addToast(DETETE_ERROR_TOAST);
      },
      onCompleted() {
        addToast(DELETE_SUCCESS_TOAST);
      },
    }
  );

  useEffect(() => {
    commercialAgreementsList();
  }, [commercialAgreementsList]);

  const isError = error;

  useEffect(() => {
    if (isError) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, isError]);

  const isLoading = loading || commercialAgreementDeleteLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }
    if (data) {
      setListState({
        commercialAgreements: data.commercialAgreements.entries,
        hasNextPage: !!data.commercialAgreements.afterCursor,
        hasPreviousPage: !!data.commercialAgreements.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.commercialAgreements.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.commercialAgreements.beforeCursor,
      });
  };
  const onClickRemoveButton = (id: number) => {
    setCurrentCommercialAgreementId(id);
    showConfirm();
  };

  const onConfirmDelete = () => {
    closeConfirm();

    commercialAgreementDeleteMutation({
      variables: { id: currentCommercialAgreementId },
      update(cache) {
        updateCacheById(
          cache,
          currentCommercialAgreementId,
          CommercialAgreementTypename
        );
      },
    });
  };

  const onCancelDelete = () => closeConfirm();

  const onClickEditButton = (id: number) =>
    push(`/dashboard/billing-cycle/commercial-agreements/${id}/edit`);

  const onClickAddButton = () => push(DASHBOARD_COMMERCIAL_AGREEMENTS_CREATE_ROUTE);

  const onSubmit: SubmitHandler<FormFilters> = (commercialAgreement) => {
    !!commercialAgreement.commercialAgreementStartValidity &&
      Object.assign(commercialAgreement, {
        commercialAgreementStartValidity: formatDateToSubmitDateString(
          new Date(
            commercialAgreement.commercialAgreementStartValidity.getFullYear(),
            commercialAgreement.commercialAgreementStartValidity.getMonth(),
            1
          ).toString()
        ),
      });

    refetch({
      filters: Object.fromEntries(
        Object.entries(commercialAgreement).filter(([, value]) => !!value)
      ),
    });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title="Acordos Comerciais">
          <AddButton onClick={onClickAddButton} label="Acordos Comerciais" />
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CommercialAgreementsFormFilters
          errors={errors}
          control={control}
          register={register}
          isLoading={isLoading}
        />
      </form>

      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <CommercialAgreementsTable
              commercialAgreements={listState.commercialAgreements}
              onClickEditButton={onClickEditButton}
              onClickRemoveButton={onClickRemoveButton}
              disableEditButton={isLoading}
              disableRemoveButton={isLoading}
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
        <ConfirmOverlay
          title="Deletar acordo comercial"
          text="Tem certeza que deseja deletar este acordo comercial? 
          Essa ação não poderá ser revertida."
          variant="danger"
          onConfirm={onConfirmDelete}
          onCancel={onCancelDelete}
        />
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
