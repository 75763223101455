import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MdArrowForward } from 'react-icons/md';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import PasswordField from './PasswordField';

import {
  UserResetPasswordInput,
  USER_RESET_PASSWORD,
} from 'auth/graphql/userResetPasswordQuery';
import {
  UserResetPasswordCheckVariables,
  USER_RESET_PASSWORD_CHECK,
} from 'auth/graphql/userResetPasswordCheckQuery';
import { setFormError } from 'utils/form';
import { has } from 'lodash';
import { ErrorOption, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type UserPasswordProps = {
  userPassword?: string;
  userPasswordConfirmation?: string;
};

export default function NewPasswordForm() {
  const pageHistory = useHistory();
  const pageLocation = useLocation();

  const userToken = pageLocation.pathname.split('/')[2];

  const USER_RESET_PASSWORD_CHECK_ERROR_TOAST: ToastProps = {
    text: 'O token de recuperação de senha é inválido ou já expirou.',
    title: 'Token inválido!',
    variant: 'danger',
  };

  const USER_RESET_PASSWORD_SUCCESS_TOAST: ToastProps = {
    text: 'Senha criada com sucesso!',
    title: 'Sucesso!',
    variant: 'primary',
  };

  const LOGIN_ROUTE = '/';

  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { showLoading, closeLoading } = useLoading();

  const [user, setUser] = React.useState<UserPasswordProps>();
  const [validToken, setValidToken] = React.useState(false);

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<UserResetPasswordInput>();

  const [userResetPassword, { loading: resetPasswordLoading }] =
    useMutation<UserResetPasswordInput>(USER_RESET_PASSWORD, {
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'details')) {
          addToast(USER_RESET_PASSWORD_CHECK_ERROR_TOAST);
        }

        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof UserResetPasswordInput, error);

            setTimeout(() => clearErrors(), 2500);
          },
          t
        );

        closeLoading();
      },
      onCompleted() {
        addToast(USER_RESET_PASSWORD_SUCCESS_TOAST);
        pageHistory.push(LOGIN_ROUTE);
      },
    });

  const [userResetPasswordCheck, { loading: resetPasswordCheckLoading }] =
    useLazyQuery<UserResetPasswordCheckVariables>(USER_RESET_PASSWORD_CHECK, {
      onError() {
        addToast(USER_RESET_PASSWORD_CHECK_ERROR_TOAST);

        setTimeout(() => {
          pageHistory.push(LOGIN_ROUTE);
        }, 3000);
      },
      onCompleted() {
        closeLoading();
        setValidToken(true);
      },
    });

  const loading = resetPasswordLoading || resetPasswordCheckLoading;

  const handleSubmitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!!user?.userPassword === !!user?.userPasswordConfirmation) {
      userResetPassword({
        variables: {
          InternalUserResetPasswordInput: {
            userToken: userToken,
            userPassword: user?.userPassword,
            userPasswordConfirmation: user?.userPasswordConfirmation,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    if (!!userToken) {
      userResetPasswordCheck({
        variables: {
          userToken: userToken,
        },
      });
    } else {
      pageHistory.push('/');
    }
  }, [userResetPasswordCheck, userToken, pageHistory]);

  React.useEffect(() => {
    if (loading) {
      showLoading();
      return;
    }
  }, [loading, showLoading]);

  return (
    <form onSubmit={handleSubmitForm} className="flex flex-col gap-8 w-full h-full">
      <h3 className="text-left w-full text-gray-dark500 text-sm">
        Requisitos de criação:
        <br />• Precisam ser iguais;
        <br />• Devem conter uma letra maiúscula;
        <br />• Uma letra minúscula e um caracter especial;
        <br />• Devem ter no mínimo de 8 caracteres e máximo de 40;
      </h3>
      <PasswordField
        autoComplete="new-password"
        id="new-password"
        label="Senha"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setUser({ ...user, userPassword: event.target.value })
        }
        disabled={Object.keys(errors).length > 0 || !validToken}
      />

      <PasswordField
        autoComplete="new-password-confirmation"
        id="new-password-confirmation"
        label="Confirmar Senha"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setUser({ ...user, userPasswordConfirmation: event.target.value })
        }
        error={
          errors.userPasswordInput?.message &&
          'Verifique os requisitos de criação de senha acima'
        }
        disabled={Object.keys(errors).length > 0 || !validToken}
      />

      <div className="text-center w-full flex items-center justify-start">
        <Button
          className="w-auto"
          type="submit"
          disabled={Object.keys(errors).length > 0 || !validToken}
        >
          <p className="font-medium text-sm">Salvar senha</p>
          <MdArrowForward size={20} />
        </Button>
      </div>
    </form>
  );
}
