export type ConsumerUnitId = number;

export enum ConsumptionGroupsType {
  A = 'A',
  B = 'B',
}

export enum ConsumerUnitModalityType {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  CONVENTIONAL = 'CONVENTIONAL',
}

export enum ConsumerUnitConsumptionClassType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
  INDUSTRIAL = 'INDUSTRIAL',
}

export interface ConsumerUnit {
  id: ConsumerUnitId;
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitAddressState: string;
  consumerUnitAddressCity: string;
  consumerUnitAddressPostalCode: number;
  consumerUnitAddressStreet: string;
  consumerUnitAddressDistrict: string;
  consumerUnitAddressComplement: null | string;
  consumerUnitConsumptionGroupType: ConsumptionGroupsType;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  consumerUnitModality: ConsumerUnitModalityType;
  consumerUnitConsumptionClass: ConsumerUnitConsumptionClassType;
  cooperativeId: number;
  cooperativeMemberId: number;
  powerDistributionUnitId: number;
  cooperative: {
    id: number;
    cooperativeLegalName: string;
  };
  cooperativeMember: {
    id: number;
    cooperativeMemberLegalName: string;
    cooperativeMemberDocumentId: string;
  };
  powerDistributionUnit: {
    id: number;
    powerDistributionUnitLegalName: string;
  };
  apportionmentCycleConsumerUnit: {
    id: number;
    apportionmentCycleConsumerUnitPercentual: number;
    generationUnit: {
      id: number;
      generationUnitLegalName: string;
    };
  };
}
