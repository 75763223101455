import { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import TextField from 'ui/form/TextField';
import RadioField from 'ui/form/RadioField';
import SelectField from 'ui/form/SelectField';
import NumberOnlyField from 'ui/form/NumberOnlyField';
import { Option } from 'ui/components/form/SelectInput';
import CountryStateSelectField from 'ui/form/CountryStateSelectField';

import { PowerDistributionUnitSelect } from 'powerDistributionUnits/models/powerDistributionUnit';

import {
  GenerationUnitConsumptionClassTypes,
  GenerationUnitConsumptionGroupTypes,
  GenerationUnitModalityType,
  GenerationUnitEnergySourceTypes,
} from 'generationUnits/models/generationUnit';
import { CooperativeSelect } from 'cooperatives/models/cooperative';
import { FinancialAccountSelect } from 'financialAccounts/models/financialAccounts';

type Field =
  | 'generationUnitConsumerUnitPowerDistributionUnitIdentifier'
  | 'generationUnitLegalName'
  | 'generationUnitCapacityFactorAverage'
  | 'generationUnitEnergySource'
  | 'generationUnitAddressCity'
  | 'generationUnitAddressPostalCode'
  | 'generationUnitAddressState'
  | 'generationUnitAddressStreet'
  | 'generationUnitAddressDistrict'
  | 'generationUnitAddressComplement'
  | 'powerDistributionUnitCredentialsUser'
  | 'generationUnitModality'
  | 'generationUnitConsumptionClass'
  | 'generationUnitConsumptionGroupType'
  | 'generationUnitPowerCapacity'
  | 'generationUnitMonthlyCapacityFactor'
  | 'cooperativeId'
  | 'financialAccountId'
  | 'powerDistributionUnitId';

export const FORM_FIELDS: Field[] = [
  'generationUnitConsumerUnitPowerDistributionUnitIdentifier',
  'generationUnitLegalName',
  'generationUnitCapacityFactorAverage',
  'generationUnitEnergySource',
  'generationUnitAddressCity',
  'generationUnitAddressPostalCode',
  'generationUnitAddressState',
  'generationUnitAddressStreet',
  'generationUnitAddressDistrict',
  'generationUnitAddressComplement',
  'powerDistributionUnitCredentialsUser',
  'generationUnitModality',
  'generationUnitConsumptionClass',
  'generationUnitConsumptionGroupType',
  'generationUnitPowerCapacity',
  'generationUnitMonthlyCapacityFactor',
  'cooperativeId',
  'financialAccountId',
  'powerDistributionUnitId',
];

export type GenerationUnitMonthlyCapacityFactor = {
  generationUnitJanCapacityFactor: string;
  generationUnitFebCapacityFactor: string;
  generationUnitMarCapacityFactor: string;
  generationUnitAprCapacityFactor: string;
  generationUnitMayCapacityFactor: string;
  generationUnitJunCapacityFactor: string;
  generationUnitJulCapacityFactor: string;
  generationUnitAugCapacityFactor: string;
  generationUnitSepCapacityFactor: string;
  generationUnitOctCapacityFactor: string;
  generationUnitNovCapacityFactor: string;
  generationUnitDecCapacityFactor: string;
};

export type GenerationUnitMonthlyCapacityFactorErrors = {
  generationUnitJanCapacityFactor?: FieldError;
  generationUnitFebCapacityFactor?: FieldError;
  generationUnitMarCapacityFactor?: FieldError;
  generationUnitAprCapacityFactor?: FieldError;
  generationUnitMayCapacityFactor?: FieldError;
  generationUnitJunCapacityFactor?: FieldError;
  generationUnitJulCapacityFactor?: FieldError;
  generationUnitAugCapacityFactor?: FieldError;
  generationUnitSepCapacityFactor?: FieldError;
  generationUnitOctCapacityFactor?: FieldError;
  generationUnitNovCapacityFactor?: FieldError;
  generationUnitDecCapacityFactor?: FieldError;
};

export type FormFields = {
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: string;
  generationUnitLegalName: string;
  generationUnitCapacityFactorAverage: string;
  generationUnitEnergySource: GenerationUnitEnergySourceTypes;
  generationUnitAddressState: string;
  generationUnitAddressCity: string;
  generationUnitAddressPostalCode: number;
  generationUnitAddressStreet: string;
  generationUnitAddressDistrict: string;
  generationUnitAddressComplement: string;
  generationUnitConsumptionGroupType: GenerationUnitConsumptionGroupTypes;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  generationUnitModality: GenerationUnitModalityType;
  generationUnitConsumptionClass: GenerationUnitConsumptionClassTypes;
  generationUnitPowerCapacity: number;
  generationUnitMonthlyCapacityFactor: GenerationUnitMonthlyCapacityFactor;
  cooperativeId: number;
  financialAccountId: number;
  powerDistributionUnitId: number;
};

export type FormErrors = {
  generationUnitConsumerUnitPowerDistributionUnitIdentifier?: FieldError;
  generationUnitLegalName?: FieldError;
  generationUnitCapacityFactorAverage?: FieldError;
  generationUnitEnergySource?: FieldError;
  generationUnitAddressState?: FieldError;
  generationUnitAddressCity?: FieldError;
  generationUnitAddressPostalCode?: FieldError;
  generationUnitAddressStreet?: FieldError;
  generationUnitAddressDistrict?: FieldError;
  generationUnitAddressComplement?: FieldError;
  generationUnitConsumptionGroupType?: FieldError;
  powerDistributionUnitCredentialsUser?: FieldError;
  powerDistributionUnitCredentialsPasswordInput?: FieldError;
  generationUnitModality?: FieldError;
  generationUnitConsumptionClass?: FieldError;
  generationUnitPowerCapacity?: FieldError;
  generationUnitMonthlyCapacityFactor?: GenerationUnitMonthlyCapacityFactorErrors;
  generationUnitJanCapacityFactor?: FieldError;
  generationUnitFebCapacityFactor?: FieldError;
  generationUnitMarCapacityFactor?: FieldError;
  generationUnitAprCapacityFactor?: FieldError;
  generationUnitMayCapacityFactor?: FieldError;
  generationUnitJunCapacityFactor?: FieldError;
  generationUnitJulCapacityFactor?: FieldError;
  generationUnitAugCapacityFactor?: FieldError;
  generationUnitSepCapacityFactor?: FieldError;
  generationUnitOctCapacityFactor?: FieldError;
  generationUnitNovCapacityFactor?: FieldError;
  generationUnitDecCapacityFactor?: FieldError;
  generationUnitSdpId?: FieldError;
  cooperativeId?: FieldError;
  financialAccountId?: FieldError;
  powerDistributionUnitId?: FieldError;
};

const generationUnitConsumptionClassOptions = [
  {
    key: 'COMMERCIAL',
    value: 'COMERCIAL',
  },
  {
    key: 'RESIDENTIAL',
    value: 'RESIDENCIAL',
  },
  {
    key: 'INDUSTRIAL',
    value: 'INDUSTRIAL',
  },
];

const generationUnitEnergySourceOptions = [
  {
    key: 'UTE',
    value: 'Termelétrica',
  },
  {
    key: 'EOL',
    value: 'Eólica',
  },
  {
    key: 'UFV',
    value: 'Solar',
  },
  {
    key: 'CGH',
    value: 'Hidráulica',
  },
];

export default function GenerationUnitFormFields({
  errors,
  register,
  powerDistributionUnits,
  cooperatives,
  financialAccounts,
  control,
  disableFields,
}: {
  errors: FormErrors;
  register: UseFormRegister<FormFields>;
  powerDistributionUnits?: PowerDistributionUnitSelect[];
  cooperatives?: CooperativeSelect[];
  financialAccounts?: FinancialAccountSelect[];
  disableFields?: boolean;
  control: Control<FormFields>;
}) {
  const [powerDistributionUnitOptions, setPowerDistributionUnitOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setPowerDistributionUnitOptions(
        (powerDistributionUnits instanceof Array &&
          powerDistributionUnits.map((powerDistributionUnit) => ({
            key: powerDistributionUnit.id,
            value: powerDistributionUnit.powerDistributionUnitLegalName,
          }))) ||
          []
      ),
    [powerDistributionUnits]
  );

  const [cooperativeOptions, setCooperativeOptions] = useState<Option<number>[]>([]);
  useEffect(
    () =>
      setCooperativeOptions(
        (cooperatives instanceof Array &&
          cooperatives.map((cooperative) => ({
            key: cooperative.id,
            value: `${cooperative.cooperativeLegalName} (${
              cooperative.cooperativeHeadquarter ? 'Filial' : 'Matriz'
            }) - ${cooperative.cooperativeAddressState}`,
          }))) ||
          []
      ),
    [cooperatives]
  );

  const [financialAccountOptions, setFinancialAccountOptions] = useState<
    Option<number>[]
  >([]);
  useEffect(
    () =>
      setFinancialAccountOptions(
        (financialAccounts instanceof Array &&
          financialAccounts.map((financialAccount) => ({
            key: financialAccount.id,
            value: financialAccount.financialAccountLegalName,
          }))) ||
          []
      ),
    [financialAccounts]
  );

  const [generationUnitModalities] = useState<Option<string>[]>([
    { key: 'GREEN', value: 'VERDE' },
    { key: 'BLUE', value: 'AZUL' },
    { key: 'CONVENTIONAL', value: 'CONVENCIONAL' },
  ]);

  const [generationUnitModalityOptions, setGenerationUnitModalityOptions] = useState<
    Option<string>[]
  >([]);
  useEffect(
    () =>
      setGenerationUnitModalityOptions(
        (generationUnitModalities instanceof Array &&
          generationUnitModalities.map((generationUnitModality) => ({
            key: `${generationUnitModality.key}`,
            value: generationUnitModality.value,
          }))) ||
          []
      ),
    [generationUnitModalities]
  );

  return (
    <>
      <div className="grid grid-cols-3 gap-8 mb-6">
        <TextField
          required
          error={errors.generationUnitLegalName?.message}
          label="Nome da Usina"
          id="generationUnitLegalName"
          {...register('generationUnitLegalName', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Lajeado Grande"
        />

        <Controller
          name="financialAccountId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.financialAccountId?.message}
              label="Conta Financeira"
              shortCreation="/dashboard/billing-cycle/financial-accounts/create"
              emptyOptionLabel="Escolha uma Conta Financeira"
              id="financialAccountId"
              options={financialAccountOptions}
            />
          )}
        />
      </div>

      <div className="grid grid-cols-3 gap-8">
        <TextField
          required
          error={
            errors?.generationUnitConsumerUnitPowerDistributionUnitIdentifier
              ?.message
          }
          label="Número da UC"
          id={'generationUnitConsumerUnitPowerDistributionUnitIdentifier'}
          {...register('generationUnitConsumerUnitPowerDistributionUnitIdentifier', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex: 43213422"
          disabled={disableFields}
        />

        <Controller
          name="cooperativeId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.cooperativeId?.message}
              label="Cooperativa"
              emptyOptionLabel="Escolha uma Cooperativa"
              id="cooperativeId"
              options={cooperativeOptions}
              disabled={disableFields}
            />
          )}
        />

        <Controller
          name="powerDistributionUnitId"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.powerDistributionUnitId?.message}
              label="Concessionária"
              emptyOptionLabel="Escolha uma Concessionária"
              id="powerDistributionUnitId"
              options={powerDistributionUnitOptions}
              disabled={disableFields}
            />
          )}
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Informações Técnicas
      </p>

      <div className="grid grid-cols-3 gap-8 mb-6">
        <Controller
          name="generationUnitConsumptionGroupType"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <RadioField
              required
              {...omit(field, 'ref')}
              error={errors.generationUnitConsumptionGroupType?.message}
              label="Grupo"
              id="generationUnitConsumptionGroupType"
              options={[
                {
                  label: 'Grupo A',
                  value: 'A',
                },
                {
                  label: 'Grupo B',
                  value: 'B',
                },
              ]}
              disabled={disableFields}
            />
          )}
        />

        <NumberOnlyField
          required
          error={errors.generationUnitPowerCapacity?.message}
          label="Potência da Usina (kW)"
          id="generationUnitPowerCapacity"
          {...register('generationUnitPowerCapacity', {
            required: 'Campo obrigatório',
            valueAsNumber: true,
          })}
          placeholder="Ex: 5000"
        />
      </div>

      <div className="grid grid-cols-3 gap-8 mb-6">
        <Controller
          name="generationUnitConsumptionClass"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.generationUnitConsumptionClass?.message}
              label="Classe de Consumo"
              emptyOptionLabel="Escolha uma classe de consumo"
              id="generationUnitConsumptionClass"
              options={generationUnitConsumptionClassOptions}
              disabled={disableFields}
            />
          )}
        />

        <Controller
          name="generationUnitModality"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.generationUnitModality?.message}
              label="Modalidade"
              emptyOptionLabel="Escolha uma Modalidade"
              id="generationUnitModality"
              options={generationUnitModalityOptions}
              disabled={disableFields}
            />
          )}
        />
        <Controller
          name="generationUnitEnergySource"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <SelectField
              required
              {...omit(field, 'ref')}
              error={errors.generationUnitEnergySource?.message}
              label="Fonte de Energia"
              emptyOptionLabel="Escolha a fonte de energia"
              id="generationUnitEnergySource"
              options={generationUnitEnergySourceOptions}
            />
          )}
        />
      </div>
      <div className="grid grid-cols-3 gap-8">
        <TextField
          required
          error={errors.powerDistributionUnitCredentialsUser?.message}
          label="Usuário da Concessionária"
          id="powerDistributionUnitCredentialsUser"
          {...register('powerDistributionUnitCredentialsUser', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: exe@gmail.com ou 28.054.303/0001-06"
        />
        <TextField
          error={errors.powerDistributionUnitCredentialsPasswordInput?.message}
          label="Senha da Concessionária"
          id="powerDistributionUnitCredentialsPasswordInput"
          {...register('powerDistributionUnitCredentialsPasswordInput')}
          placeholder="*******"
        />
      </div>

      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Fator de capacidade médio mensal
      </p>
      <div className="grid grid-cols-6 gap-8">
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitJanCapacityFactor?.message ||
            errors.generationUnitJanCapacityFactor?.message
          }
          label="Janeiro"
          id="generationUnitJanCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJanCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitFebCapacityFactor?.message ||
            errors.generationUnitFebCapacityFactor?.message
          }
          label="Fevereiro"
          id="generationUnitFebCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitFebCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitMarCapacityFactor?.message ||
            errors.generationUnitMarCapacityFactor?.message
          }
          label="Março"
          id="generationUnitMarCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitMarCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitAprCapacityFactor?.message ||
            errors.generationUnitAprCapacityFactor?.message
          }
          label="Abril"
          id="generationUnitAprCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitAprCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitMayCapacityFactor?.message ||
            errors.generationUnitMayCapacityFactor?.message
          }
          label="Maio"
          id="generationUnitMayCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitMayCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitJunCapacityFactor?.message ||
            errors.generationUnitJunCapacityFactor?.message
          }
          label="Junho"
          id="generationUnitJunCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJunCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitJulCapacityFactor?.message ||
            errors.generationUnitJulCapacityFactor?.message
          }
          label="Julho"
          id="generationUnitJulCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitJulCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitAugCapacityFactor?.message ||
            errors.generationUnitAugCapacityFactor?.message
          }
          label="Agosto"
          id="generationUnitAugCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitAugCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitSepCapacityFactor?.message ||
            errors.generationUnitSepCapacityFactor?.message
          }
          label="Setembro"
          id="generationUnitSepCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitSepCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitOctCapacityFactor?.message ||
            errors.generationUnitOctCapacityFactor?.message
          }
          label="Outubro"
          id="generationUnitOctCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitOctCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitNovCapacityFactor?.message ||
            errors.generationUnitNovCapacityFactor?.message
          }
          label="Novembro"
          id="generationUnitNovCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitNovCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
        <TextField
          required
          error={
            errors.generationUnitMonthlyCapacityFactor
              ?.generationUnitDecCapacityFactor?.message ||
            errors.generationUnitDecCapacityFactor?.message
          }
          label="Dezembro"
          id="generationUnitDecCapacityFactor"
          {...register(
            'generationUnitMonthlyCapacityFactor.generationUnitDecCapacityFactor',
            { required: 'Campo obrigatório' }
          )}
          placeholder="Ex.: 0.201"
        />
      </div>

      <hr className="mt-16 mb-4" />
      <p className="text-gray-dark400 font-bold text-sm pt-8 pb-6">
        Endereço da Usina
      </p>
      <div className="grid grid-cols-3 gap-8">
        <NumberOnlyField
          required
          error={errors.generationUnitAddressPostalCode?.message}
          label="CEP"
          id="generationUnitAddressPostalCode"
          {...register('generationUnitAddressPostalCode', {
            required: 'Campo obrigatório',
            valueAsNumber: true,
          })}
          placeholder="81000000"
          minLength={8}
          maxLength={8}
        />
        <Controller
          name="generationUnitAddressState"
          control={control}
          rules={{ required: 'Campo obrigatório' }}
          render={({ field }) => (
            <CountryStateSelectField
              required
              {...omit(field, 'ref')}
              error={errors.generationUnitAddressState?.message}
              label="Estado"
              id="generationUnitAddressState"
            />
          )}
        />
        <TextField
          required
          error={errors.generationUnitAddressCity?.message}
          label="Cidade"
          id="generationUnitAddressCity"
          {...register('generationUnitAddressCity', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Curitiba"
        />
        <TextField
          error={errors.generationUnitAddressDistrict?.message}
          label="Bairro"
          id="generationUnitAddressDistrict"
          {...register('generationUnitAddressDistrict')}
          placeholder="Ex.: Centro"
        />
        <TextField
          required
          error={errors.generationUnitAddressStreet?.message}
          label="Endereço"
          id="generationUnitAddressStreet"
          {...register('generationUnitAddressStreet', {
            required: 'Campo obrigatório',
          })}
          placeholder="Ex.: Comendador Franco 777"
        />
        <TextField
          error={errors.generationUnitAddressComplement?.message}
          label="Complemento"
          id="generationUnitAddressComplement"
          {...register('generationUnitAddressComplement', {
            required: false,
          })}
          placeholder="Ex.: Sala 1"
        />
      </div>
      <div className="h-28" />
    </>
  );
}
