import { camelCase } from 'lodash';
import { ApolloError } from '@apollo/client';
import { ErrorOption } from 'react-hook-form';
import { TFunction } from 'i18next';

export const setFormError = (
  error: ApolloError,
  setError: (
    field: string,
    error: ErrorOption,
    options: { shouldFocus: true }
  ) => void,
  t: TFunction
) => {
  let errors: { [key: string]: string } = {};

  return error.message.split('\n').forEach((errorMessage: string) => {
    const errorMessageSlices = errorMessage.split(' ');
    const errorType = errorMessageSlices[0];
    const errorMesage = errors[errorType] ?? '';

    errors[errorType] = errorMesage
      .concat(' ')
      .concat(t(errorMessageSlices.splice(1).join(' ')));

    setError(
      camelCase(errorType),
      {
        type: 'manual',
        message: errors[errorType],
      },
      { shouldFocus: true }
    );
  });
};

export const FORM_ERRORS_MAP: Record<string, string> = {
  required: 'Campo obrigatório!',
};

const LOCALE_DATE_CONFIG = {
  locale: 'pt-BR',
  timeZone: 'UTC',
  options: { month: '2-digit', year: 'numeric' } as Intl.DateTimeFormatOptions,
};

const getPartsFromDate = (
  date: string,
  options: Intl.DateTimeFormatOptions | undefined
) =>
  new Intl.DateTimeFormat(LOCALE_DATE_CONFIG.locale, options).format(new Date(date));

export const dateWithoutTimezone = (date: Date) =>
  new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);

export const dateWithTimezone = (date: Date) =>
  new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

export const convertToDecimal = (value: string, minimumFractionDigits?: number) => {
  return new Intl.NumberFormat(LOCALE_DATE_CONFIG.locale, {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits || 2,
  }).format(Number(value) || 0);
};

export const convertToBRL = (value: string) => {
  return new Intl.NumberFormat(LOCALE_DATE_CONFIG.locale, {
    style: 'currency',
    currency: 'BRL',
  }).format(Number(value));
};

export const convertToParseFloat = (value: string) => {
  return parseFloat(value.replace(/\./g, '').replace(',', '.')).toFixed(2);
};

export const formatDateToSubmitDateString = (date: string) =>
  `${getPartsFromDate(date, {
    year: 'numeric',
    timeZone: LOCALE_DATE_CONFIG.timeZone,
  })}-${getPartsFromDate(date, {
    month: '2-digit',
    timeZone: LOCALE_DATE_CONFIG.timeZone,
  })}-${getPartsFromDate(date, {
    day: '2-digit',
    timeZone: LOCALE_DATE_CONFIG.timeZone,
  })}`;

export const formatDateToLocaleDateString = (
  date: string,
  options?: Intl.DateTimeFormatOptions
) =>
  new Date(date).toLocaleDateString(LOCALE_DATE_CONFIG.locale, {
    ...options,
    timeZone: LOCALE_DATE_CONFIG.timeZone,
  });

export function handleValidateEntityType(entityType: string) {
  switch (entityType) {
    case 'INDIVIDUAL':
      return 'PF';
    case 'COMPANY':
      return 'PJ';
    default:
      break;
  }
}
