import React from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { MdDone, MdInfoOutline } from 'react-icons/md';
import ReactModal from 'react-modal';
import { Variant } from 'ui/models/overlay';

type Props = {
  variant: Variant;
  onCloseModal: () => void;
  isOpen: boolean;
  contentLabel: string;
  shouldCloseOnOverlayClick?: boolean;
  shouldCloseOnEsc?: boolean;
  children: React.ReactNode;
};

const variants: Record<
  Variant,
  { icon: React.ReactNode; confirmButton: 'primaryRed' | 'primaryGreen' }
> = {
  danger: {
    icon: <MdInfoOutline className="text-red-a1 text-2xl" />,
    confirmButton: 'primaryRed',
  },
  primary: {
    icon: <MdDone className="text-primary text-2xl" />,
    confirmButton: 'primaryGreen',
  },
  warning: {
    icon: <FiAlertTriangle className="text-red-a1 text-2xl" />,
    confirmButton: 'primaryRed',
  },
};

const Modal = ({
  isOpen,
  onCloseModal,
  contentLabel,
  shouldCloseOnOverlayClick,
  shouldCloseOnEsc,
  variant,
  children,
}: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onCloseModal}
      contentLabel={contentLabel}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      overlayElement={(overlayProps, contentElement) => (
        <div
          {...overlayProps}
          className="fixed inset-0 h-full w-full bg-black bg-opacity-70 flex items-center justify-center z-40"
          style={{}}
        >
          {contentElement}
        </div>
      )}
      contentElement={({ title, ...props }) => (
        <div {...props} className="flex items-center justify-center" style={{}}>
          <div
            className="bg-gray-dark700 rounded-lg shadow-xl p-8 max-w-2xl flex"
            style={{ minWidth: '512px', maxWidth: '600px' }}
          >
            <div className="mr-5">
              <div className="bg-gray-dark600 p-2 rounded-full">
                {variants[variant].icon}
              </div>
            </div>
            <div>
              <h4 className="text-gray-dark400 font-medium text-lg">
                {contentLabel}
              </h4>

              <div className="ml-0">{children}</div>
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Modal;
