export type GenerationUnitId = number;

export enum GenerationUnitEnergySourceTypes {
  UTE = 'UTE',
  EOL = 'EOL',
  UFV = 'UFV',
  CGH = 'CGH',
}

export enum GenerationUnitModalityType {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  CONVENTIONAL = 'CONVENTIONAL',
}

export enum GenerationUnitConsumptionClassTypes {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
  INDUSTRIAL = 'INDUSTRIAL',
}

export enum GenerationUnitConsumptionGroupTypes {
  A = 'A',
  B = 'B',
}

export interface GenerationUnitSelectModel {
  id: GenerationUnitId;
  generationUnitLegalName: string;
  generationUnitEnergySource: GenerationUnitEnergySourceTypes;
}

export interface GenerationUnit {
  id: GenerationUnitId;
  generationUnitConsumerUnitPowerDistributionUnitIdentifier: string;
  generationUnitLegalName: string;
  generationUnitCapacityFactorAverage: string;
  generationUnitEnergySource: GenerationUnitEnergySourceTypes;
  generationUnitAddressState: string;
  generationUnitAddressCity: string;
  generationUnitAddressPostalCode: number;
  generationUnitAddressStreet: string;
  generationUnitAddressDistrict: string;
  generationUnitAddressComplement: null | string;
  generationUnitConsumptionGroupType: GenerationUnitConsumptionGroupTypes;
  powerDistributionUnitCredentialsUser: string;
  powerDistributionUnitCredentialsPasswordInput: string;
  generationUnitModality: GenerationUnitModalityType;
  generationUnitConsumptionClass: GenerationUnitConsumptionClassTypes;
  generationUnitPowerCapacity: number;
  generationUnitMonthlyCapacityFactor: {
    generationUnitJanCapacityFactor: string;
    generationUnitFebCapacityFactor: string;
    generationUnitMarCapacityFactor: string;
    generationUnitAprCapacityFactor: string;
    generationUnitMayCapacityFactor: string;
    generationUnitJunCapacityFactor: string;
    generationUnitJulCapacityFactor: string;
    generationUnitAugCapacityFactor: string;
    generationUnitSepCapacityFactor: string;
    generationUnitOctCapacityFactor: string;
    generationUnitNovCapacityFactor: string;
    generationUnitDecCapacityFactor: string;
  };
  cooperativeId: number;
  financialAccountId: number;
  powerDistributionUnitId: number;
  cooperative: {
    id: number;
    cooperativeLegalName: string;
  };
  financialAccount: {
    id: number;
    financialAccountLegalName: string;
  };
  powerDistributionUnit: {
    id: number;
    powerDistributionUnitLegalName: string;
  };
}

export interface GenerationUnitSelect {
  id: GenerationUnitId;
  generationUnitLegalName: string;
}
