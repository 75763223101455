import classNames from 'classnames';
import React from 'react';
import { TextInput } from 'ui';
import { Props as BaseProps } from 'ui/components/form/BaseInput';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> &
    BaseProps & {
      id: string;
      label?: string;
      wrapperClassName?: string;
    },
  HTMLInputElement
>;

export const TextField = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      mask,
      name,
      type,
      label,
      error,
      disabled,
      required,
      className,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    return (
      <fieldset className={wrapperClassName}>
        <div className="flex mb-3">
          <label
            htmlFor={id}
            className={classNames('block text-sm font-medium text-gray-dark400', {
              'text-gray-dark400 opacity-30': disabled,
            })}
          >
            {label}
          </label>
          {required && (
            <p
              className={classNames('text-gray-dark400 h-0', {
                'text-gray-dark400 opacity-30': disabled,
              })}
            >
              *
            </p>
          )}
        </div>
        <TextInput
          id={id}
          ref={ref}
          name={name}
          type={type}
          mask={mask}
          error={error}
          disabled={disabled}
          className={className}
          {...props}
        />
      </fieldset>
    );
  }
);

export default TextField;
