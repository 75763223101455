import { gql } from '@apollo/client';

export interface GenerationUnitQueryVariables {
  id: string;
}

export const GenerationUnitsTypename = 'GenerationUnit';

export const GENERATION_UNIT_QUERY = gql`
  query generationUnit($id: ID!) {
    generationUnit(id: $id) {
      id
      generationUnitConsumerUnitPowerDistributionUnitIdentifier
      generationUnitLegalName
      generationUnitCapacityFactorAverage
      generationUnitEnergySource
      generationUnitPowerCapacity
      generationUnitAddressCity
      generationUnitAddressPostalCode
      generationUnitAddressState
      generationUnitAddressStreet
      generationUnitAddressDistrict
      generationUnitAddressComplement
      powerDistributionUnitCredentialsUser
      generationUnitModality
      generationUnitConsumptionClass
      generationUnitConsumptionGroupType
      generationUnitMonthlyCapacityFactor {
        generationUnitJanCapacityFactor
        generationUnitFebCapacityFactor
        generationUnitMarCapacityFactor
        generationUnitAprCapacityFactor
        generationUnitMayCapacityFactor
        generationUnitJunCapacityFactor
        generationUnitJulCapacityFactor
        generationUnitAugCapacityFactor
        generationUnitSepCapacityFactor
        generationUnitOctCapacityFactor
        generationUnitNovCapacityFactor
        generationUnitDecCapacityFactor
      }
      cooperative {
        id
        cooperativeLegalName
      }
      financialAccount {
        id
        financialAccountLegalName
      }
      powerDistributionUnit {
        id
        powerDistributionUnitLegalName
      }
    }
  }
`;
