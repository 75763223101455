import { Button } from 'ui';

import { MdClose } from 'react-icons/md';
import { IoMdCloudDownload } from 'react-icons/io';
import { VscCircleOutline } from 'react-icons/vsc';

import {
  convertToBRL,
  convertToDecimal,
  formatDateToLocaleDateString,
} from 'utils/form';

import { ConsumerUnitBillingCycle } from 'consumerUnits/consumerUnitBillingCycles/models/consumerUnitBillingCycles';

import {
  BillConsumedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
  BillConsumedEnergyKwh,
} from '../../models/consumerUnitBillData';

export type Props = {
  onClickCloseModal?: () => void;
  handleClickDownloadBill?: () => void;
  consumerUnitBillingCycle?: ConsumerUnitBillingCycle;
};

export default function ConsumerUnitBillDataDetailsItem({
  onClickCloseModal,
  handleClickDownloadBill,
  consumerUnitBillingCycle,
}: Props) {
  const {
    powerDistributionUnitBillData: {
      billIssueDate = 0,
      billTotalValue = '0',
      billReferenceDate = 0,
      billNextReadingDate = 0,
      billReadingStartedAt = 0,
      billReadingFinishedAt = 0,
      billConsumedEnergyKwh = {} as BillConsumedEnergyKwh,
      billConsumedEnergyCredits = {} as BillConsumedEnergyCredits,
      billConsumedEnergyCreditsTariffs = {} as BillConsumedEnergyCreditsTariffs,
    } = {},
  } = consumerUnitBillingCycle || {};

  return (
    <div className="flex flex-col items-start justify-start gap-2 h-screen fixed bg-gray-dark700 right-0 top-0 border-l border-gray-dark600">
      <div className="flex px-10 py-8 items-center justify-start gap-12 border-b border-gray-dark600">
        <h1 className="text-gray-dark400 w-full font-normal text-2xl">
          Detalhes da fatura
        </h1>
        <Button
          type="button"
          size="none"
          variant="tertiaryGray"
          style={{
            padding: '.1rem',
          }}
        >
          <MdClose size={20} color="#6B6B6B" onClick={onClickCloseModal} />
        </Button>
      </div>
      <div className="w-full flex flex-col px-10 py-8 gap-4">
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">
            Mês de referência da fatura:
          </h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billReferenceDate as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Data de disponibilização:</h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billIssueDate as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Ciclo de consumo:</h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billReadingStartedAt as string)} -{' '}
            {formatDateToLocaleDateString(billReadingFinishedAt as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Data de leitura futura:</h2>
          <p className="text-gray-dark400">
            {formatDateToLocaleDateString(billNextReadingDate as string)}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">Valor da fatura:</h2>
          <p className="text-gray-dark400">{convertToBRL(billTotalValue)}</p>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-4">
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">Consumo:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(billConsumedEnergyKwh?.consumedEnergyKwhTotal || '0')}{' '}
            kWh
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">
            Créditos compensados:
          </h2>
          <p className="text-gray-dark400">
            {convertToDecimal(
              billConsumedEnergyCredits?.consumedEnergyCreditsTotal || '0'
            )}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Tarifa compensada:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(
              billConsumedEnergyCreditsTariffs?.consumedEnergyCreditsTariffsTotal ||
                '0'
            )}
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4 font-bold">Bandeira(s):</h2>
          {/* REFACTOR: WE NEED TO INCLUDE THE DAYS OF CURRENT TARIFF FLAG AND THE COLOR OF IT */}
          <p className="text-gray-dark400 flex gap-4 items-center justify-start">
            <span className="flex items-center justify-start">
              <VscCircleOutline color="#F2994A" size={20} /> 10
            </span>
            <span className="flex items-center justify-start">
              <VscCircleOutline color="#27AE60" size={20} /> 20
            </span>
          </p>
        </div>
        <div>
          <h2 className="text-gray-dark400 leading-4">Total:</h2>
          {/* REFACTOR: WE NEED TO INCLUDE THE TOTAL DAYS OF TARIFF FLAG REFERENCE */}
          <p className="text-gray-dark400">30 dias</p>
        </div>
      </div>
      <Button
        size="none"
        type="button"
        variant="tertiaryGray"
        onClick={handleClickDownloadBill}
        className="flex justify-start items-center p-2 ml-10"
      >
        <IoMdCloudDownload
          size={30}
          color="#B6B6B6"
          style={{
            marginLeft: '5px',
            marginRight: '5px',
          }}
        />
        <div className="flex flex-col justify-center items-start cursor-pointer">
          <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
            Baixar fatura
          </p>
        </div>
      </Button>
    </div>
  );
}
