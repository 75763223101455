import { SyntheticEvent } from 'react';
import { FinancialAccount } from 'financialAccounts/models/financialAccounts';
import { TableBodyColumn } from 'ui/components/table';
import { EditButton, RemoveButton } from 'dashboard/components/table/buttons';
import TableBodyRow from 'ui/components/table/TableBodyRow';
import { handleValidateEntityType } from 'utils/form';

type Props = {
  financialAccounts: FinancialAccount;
  onClickEditButton: (id: number) => void;
  onClickRemoveButton: (id: number) => void;
};

export default function FinancialAccountsTableItem({
  financialAccounts: {
    id,
    financialAccountLegalName,
    financialAccountEntityType,
    financialAccountDocumentId,
    financialAccountAddressPostalCode,
    financialAccountAddressState,
  },
  onClickEditButton,
  onClickRemoveButton,
}: Props) {
  const handleClickEditButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickEditButton(id);
  };

  const handleClickRemoveButton = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    onClickRemoveButton(id);
  };

  return (
    <TableBodyRow dataTestId={`financial-accounts-row-id-${id}`}>
      <TableBodyColumn>{financialAccountLegalName}</TableBodyColumn>
      <TableBodyColumn>
        {handleValidateEntityType(financialAccountEntityType)}
      </TableBodyColumn>
      <TableBodyColumn>{financialAccountDocumentId}</TableBodyColumn>
      <TableBodyColumn>{financialAccountAddressState}</TableBodyColumn>
      <TableBodyColumn>{financialAccountAddressPostalCode}</TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        <EditButton onClick={handleClickEditButton} />
        <RemoveButton onClick={handleClickRemoveButton} />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
