import classNames from 'classnames';
import SelectInput, { Props as BaseProps } from 'ui/components/form/SelectInput';

export type Props<T> = BaseProps<T> & {
  label: string;
  wrapperClassName?: string;
  shortCreation?: string;
};

const SelectField = <T extends unknown>({
  required,
  className,
  wrapperClassName,
  shortCreation,
  label,
  error,
  disabled,
  id,
  ...props
}: Props<T>) => {
  const labelId = `${id}__label`;

  return (
    <fieldset className={wrapperClassName}>
      <div className="flex mb-3">
        <label
          id={labelId}
          className={classNames('block text-sm font-medium text-gray-dark400', {
            'text-gray-dark400 opacity-30': disabled,
          })}
        >
          {label}
        </label>
        {required && (
          <p
            className={classNames('text-gray-dark400 h-0', {
              'text-gray-dark400 opacity-30': disabled,
            })}
          >
            *
          </p>
        )}
        {shortCreation && (
          <a
            className="ml-3 text-sm font-medium text-gray-dark500 underline"
            href={shortCreation}
          >
            Cadastrar aqui
          </a>
        )}
      </div>
      <SelectInput
        {...props}
        error={error}
        disabled={disabled}
        aria-labelledby={labelId}
      />
    </fieldset>
  );
};

export default SelectField;
