import { ApolloError, useMutation } from '@apollo/client';
import has from 'lodash/has';
import { useEffect } from 'react';
import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TariffFlagModalityFormFields, {
  FormFields,
} from 'tariffFlagTariffs/components/form/TariffFlagModalityFormFields';
import {
  TariffFlagModalitiesList,
  tariffFlagModalitiesTypename,
  TARIFF_FLAG_MODALITIES_QUERY,
} from 'tariffFlagTariffs/graphql/tariffFlagModalitiesQuery';
import {
  TariffFlagModalityCreated,
  TariffFlagModalityMutationVariables,
  TARIFF_FLAG_MODALITY_CREATE_MUTATION,
} from 'tariffFlagTariffs/graphql/tariffFlagModalityCreateMutation';
import { Button } from 'ui';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import useToastContext from 'ui/hooks/useToast';
import { ModalFormProps } from 'ui/models/overlay';
import { formatDateToSubmitDateString, setFormError } from 'utils/form';

type Props = {
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  onClickCloseModal: () => void;
};

const CREATE_ERROR_TOAST: ToastProps = {
  text: 'Houve um erro ao tentar criar bandeira.',
  title: 'Algo deu errado!',
  variant: 'danger',
};

const CREATE_SUCCESS_TOAST: ToastProps = {
  title: 'Successo!',
  variant: 'primary',
  text: 'Nova bandeira cadastrada com sucesso.',
};

const CREATE_WARNING_TOAST: ToastProps = {
  title: 'Alerta!',
  variant: 'danger',
  text: 'Bandeira criada com sucesso mas sem bandeira tarifária criada para o período informado.',
};

export default function CreateTariffFlagModality({
  ModalOverlay,
  onClickCloseModal,
}: Props) {
  const {
    handleSubmit,
    setError,
    clearErrors,
    control,
    register,
    formState: { errors },
  } = useForm<FormFields>();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  const [tariffFlagModalityCreateMutation, { loading: loadingCreate }] = useMutation<
    TariffFlagModalityCreated,
    TariffFlagModalityMutationVariables
  >(TARIFF_FLAG_MODALITY_CREATE_MUTATION, {
    onError(error: ApolloError) {
      if (has(error.graphQLErrors[0], 'details')) {
        onClickCloseModal();

        addToast(CREATE_ERROR_TOAST);
      }

      if (error.graphQLErrors[0]?.message === 'tariff_flag_modality_not_found') {
        onClickCloseModal();

        addToast(CREATE_WARNING_TOAST);
      }

      setFormError(
        error,
        (field: string, error: ErrorOption) => {
          setError(field as keyof FormFields, error);

          setTimeout(() => clearErrors(), 2500);
        },
        t
      );
    },
    onCompleted() {
      onClickCloseModal();

      addToast(CREATE_SUCCESS_TOAST);
    },
  });

  const onSubmit: SubmitHandler<FormFields> = (tariffFlagModalityCreateInput) => {
    const tariffFlagModalityValidityDate =
      tariffFlagModalityCreateInput.tariffFlagModalityValidityDate;
    const convertTariffFlagModalityValidityDateTofirstDay = new Date(
      tariffFlagModalityValidityDate.getFullYear(),
      tariffFlagModalityValidityDate.getMonth(),
      10
    );

    tariffFlagModalityCreateMutation({
      variables: {
        tariffFlagModalityCreateInput: {
          tariffFlagModalityModalityFlag:
            tariffFlagModalityCreateInput.tariffFlagModalityModalityFlag,
          tariffFlagModalityValidityDate: formatDateToSubmitDateString(
            String(convertTariffFlagModalityValidityDateTofirstDay)
          ),
        },
      },
      update(cache, { data }) {
        const existingData = cache.readQuery<TariffFlagModalitiesList>({
          query: TARIFF_FLAG_MODALITIES_QUERY,
        });
        cache.writeQuery({
          query: TARIFF_FLAG_MODALITIES_QUERY,
          data: {
            tariffFlagModalities: {
              __typename: tariffFlagModalitiesTypename,
              afterCursor: existingData?.tariffFlagModalities.afterCursor || null,
              beforeCursor: existingData?.tariffFlagModalities.beforeCursor || null,
              entries: [
                data?.tariffFlagModalityCreate,
                ...(existingData?.tariffFlagModalities.entries || []),
              ],
            },
          },
        });
      },
    });
  };

  const isLoading = loadingCreate;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  return (
    <ModalOverlay title="Nova Bandeira" variant="primary">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TariffFlagModalityFormFields
          errors={errors}
          control={control}
          register={register}
        />

        <div className="flex flex-row-reverse mt-8">
          <Button type="submit" variant="primaryGreen" size="md">
            Adicionar
          </Button>
          <Button
            variant="primaryGray"
            className="mr-2"
            size="md"
            onClick={onClickCloseModal}
          >
            Cancelar
          </Button>
        </div>
      </form>

      <LoadingOverlay />
    </ModalOverlay>
  );
}
